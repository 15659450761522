const LabelsLocaleKeys = {
  alarm: 'alarm',
  alarms: 'alarms',
  alarmGroups: 'alarmGroups',
  alarmsGroups: 'alarmsGroups',
  close: 'close',
  compartmentNumber: 'compartmentNumber',
  moreInfo: 'moreInfo',
  clearSection: 'clearSection',
  preview: 'preview',
  desc: 'desc',
  code: 'code',
  changePassword: 'changePassword',
  activeUserNo: 'activeUserNo',
  deviceNo: 'deviceNo',
  shareDeviceNo: 'shareDeviceNo',
  siteGroup: 'siteGroup',
  isAdmin: 'isAdmin',
  policy: 'policy',
  globalPolicy: 'globalPolicy',
  isActive: 'isActive',
  customerFrom: 'customerFrom',
  name: 'name',
  url: 'url',
  general: 'general',
  weeklyActivitySchedule: 'weeklyActivitySchedule',
  specialActivity: 'specialActivity',
  vacation: 'vacation',
  users: 'users',
  user: 'user',
  legend: 'legend',
  error: 'error',
  full: 'full',
  empty: 'empty',
  open: 'open',
  closed: 'closed',
  userRoles: 'userRoles',
  withoutGroup: 'withoutGroup',
  permissions: 'permissions',
  roleDescription: 'roleDescription',
  privileges: 'privileges',
  routes: 'routes',
  visits: 'visits',
  loginSettings: 'loginSettings',
  maps: 'maps',
  mapToken: 'mapToken',
  logs: 'logs',
  reports: 'reports',
  graphics: 'graphics',
  devicesSiteAssignment: 'devicesSiteAssignment',
  globalRole: 'globalRole',
  cancel: 'cancel',
  save: 'save',
  saveAndActivate: 'saveAndActivate',
  client: 'client',
  active: 'active',
  activate: 'activate',
  rowsPerPage: 'rowsPerPage',
  admin: 'admin',
  administration: 'administration',
  sites: 'sites',
  timezones: 'timezones',
  devices: 'devices',
  siteAssignment: 'siteAssignment',
  devicesConfiguration: 'devicesConfiguration',
  customer: 'customer',
  unlimitedFileSize: 'unlimitedFileSize',
  maximumFileSize: 'maximumFileSize',
  commonInfo: 'commonInfo',
  startPage: 'startPage',
  files: 'files',
  eventFeed: 'eventFeed',
  deviceStatusFeed: 'deviceStatusFeed',
  logins: 'logins',
  concurrentLoginLimit: 'concurrentLoginLimit',
  sessionDuration: 'sessionDuration',
  passwords: 'passwords',
  passwordChangeDuration: 'passwordChangeDuration',
  reminderDuration: 'reminderDuration',
  minimumPasswordLength: 'minimumPasswordLength',
  minimumNumberOfLowercase: 'minimumNumberOfLowercase',
  minimumNumberOfUppercase: 'minimumNumberOfUppercase',
  minimumNumberOfDigits: 'minimumNumberOfDigits',
  minimumNumberOfSpecialCharacters: 'minimumNumberOfSpecialCharacters',
  numberOfDaysWithoutRepetition: 'numberOfDaysWithoutRepetition',
  blockAccount: 'blockAccount',
  numberOfTrials: 'numberOfTrials',
  logTypes: 'logTypes',
  tiles: 'tiles',
  layers: 'layers',
  providers: 'providers',
  mode: 'mode',
  reportTypes: 'reportTypes',
  language: 'language',
  visitPlanAnalysisTimeWindow: 'visitPlanAnalysisTimeWindow',
  newVisitDefaultDuration: 'newVisitDefaultDuration',
  allowedVisitStartDelay: 'allowedVisitStartDelay',
  allowedVisitEndDelay: 'allowedVisitEndDelay',
  allowedVisitEarlierStart: 'allowedVisitEarlierStart',
  allowedVisitEarlierEnd: 'allowedVisitEarlierEnd',
  maxVisitSynchronizationDuration: 'maxVisitSynchronizationDuration',
  maxVisitSyncTime: 'maxVisitSyncTime',
  generateNotificationsAboutVisitsRealization: 'generateNotificationsAboutVisitsRealization',
  deleteAfter: 'deleteAfter',
  visitSynchronization: 'visitSynchronization',
  autoVisitSynchronization: 'autoVisitSynchronization',
  manualVisitsSynchronization: 'manualVisitsSynchronization',
  policyBreachEmail: 'policyBreachEmail',
  visitMissedAfter: 'visitMissedAfter',
  defaultTaskAcceptanceNeeded: 'defaultTaskAcceptanceNeeded',
  provider: 'provider',
  siteGroups: 'siteGroups',
  siteNotifications: 'siteNotifications',
  calendarTemplates: 'calendarTemplates',
  holidayTemplates: 'holidayTemplates',
  holidayTemplate: 'holidayTemplate',
  mapAreas: 'mapAreas',
  tracking: 'tracking',
  siteName: 'siteName',
  deviceName: 'deviceName',
  deviceType: 'deviceType',
  description: 'desc',
  date: 'date',
  assetName: 'assetName',
  imei: 'imei',
  phoneNumber: 'phoneNumber',
  hostedBy: 'host',
  hostedByDetailView: 'hostedBy',
  siteLinks: 'sites',
  reverseGeocoding: 'reverseGeocoding',
  reverseGeocodingType: 'reverseGeocodingType',
  login: 'login',
  signout: 'signout',
  switchSite: 'switchSite',
  switchUser: 'switchUser',
  password: 'password',
  firstName: 'firstName',
  middleName: 'middleName',
  lastName: 'lastName',
  email: 'email',
  phone: 'phone',
  receiveAdminEmail: 'receiveAdminEmail',
  userInformation: 'userInformation',
  resetPassword: 'resetPassword',
  resendActivationLink: 'resendActivationLink',
  createEmployeeInformation: 'createEmployeeInformation',
  theme: 'theme',
  timeZone: 'timeZone',
  maxMapArea: 'maxMapArea',
  assignEmployeeInformation: 'assignEmployeeInformation',
  employee: 'employee',
  assignEmployee: 'assignEmployee',
  employeeInfo: 'employeeInfo',
  settings: 'settings',
  adminEmail: 'adminEmail',
  emails: 'emails',
  languages: 'languages',
  phones: 'phones',
  picture: 'picture',
  site: 'site',
  siteThemes: 'siteThemes',
  smsAccounts: 'smsAccounts',
  userStatus: 'userStatus',
  userType: 'userType',
  visit: 'visit',
  isCloned: 'isCloned',
  deviceCode: 'deviceCode',
  deviceId: 'deviceId',
  roles: 'roles',
  ipWhiteList: 'ipWhiteList',
  addIpWhiteListItem: 'addIpWhiteListItem',
  lastActivityDate: 'lastActivityDate',
  lastActive: 'lastActivityDate',
  enabled: 'enabled',
  blocked: 'blocked',
  filter: 'filter',
  none: 'none',
  selectUser: 'selectUser',
  copySettings: 'copySettings',
  accreditation: 'accreditation',
  RFIDCard: 'RFIDCard',
  copy: 'copy',
  edit: 'edit',
  editItem: 'editItem',
  addNext: 'addNext',
  noOptions: 'noOptions',
  default: 'default',
  selected: 'selected',
  itemsSelected: 'itemsSelected',
  notifyWhenVisitStarts: 'notifyWhenVisitStarts',
  notifyWhenVisitStartIsDelayed: 'notifyWhenVisitStartIsDelayed',
  notifyWhenVisitEnds: 'notifyWhenVisitEnds',
  notifyWhenVisitEndIsDelayed: 'notifyWhenVisitEndIsDelayed',
  otherSettings: 'otherSettings',
  waitingPeriods: 'waitingPeriods',
  mapsMode: 'mapsMode',
  startFrom: 'startFrom',
  activeTo: 'activeTo',
  content: 'content',
  linkToMoreContent: 'linkToMoreContent',
  allSites: 'allSites',
  all: 'all',
  createdBy: 'createdBy',
  createdOn: 'createdOn',
  sitesCount: 'sitesCount',
  mapAreaName: 'mapAreaName',
  mapAreaGroupName: 'mapAreaGroupName',
  iconId: 'iconId',
  addressTo: 'addressTo',
  addressFrom: 'addressFrom',
  address: 'address',
  country: 'country',
  latitude: 'latitude',
  longitude: 'longtitude',
  lastVisitStatus: 'lastVisitStatus',
  lastVisitDate: 'lastVisitDate',
  realizationSettings: 'realizationSettings',
  notificationSettings: 'notificationSettings',
  sound: 'sound',
  playSound: 'playSound',
  phoneType: 'phoneType',
  basedOnSiteConfiguration: 'basedOnSiteConfiguration',
  communication: 'communication',
  confirm: 'confirm',
  tryAgain: 'tryAgain',
  isEnabled: 'active',
  remarks: 'remarks',
  ipAddress: 'ipAddress',
  ipAddressRange: 'ipAddressRange',
  externalId: 'externalId',
  latitudeDec: 'latitudeDec',
  longitudeDec: 'longitudeDec',
  drawingMode: 'drawingMode',
  ok: 'OK',
  yes: 'Yes',
  no: 'No',
  noWithDot: 'No.',
  forcePasswordChange: 'forcePasswordChange',
  aliveSignalIndicator: 'aliveSignalIndicator',
  tcpIpStatusIndicator: 'tcpIpStatusIndicator',
  deviceTime: 'deviceTime',
  deviceDriver: 'deviceDriver',
  remark: 'remark',
  serviceStatus: 'serviceStatus',
  vehicleName: 'vehicleName',
  vehicleRegistryNumber: 'vehicleRegistryNumber',
  lastKnownIp: 'lastKnownIp',
  location: 'location',
  digitalInputs: 'digitalInputs',
  keywordNames: 'keywordNames',
  counterBasedOn: 'counterBasedOn',
  privateTime: 'privateTime',
  id: 'id',
  type: 'type',
  forgotYourPassword: 'forgotYourPassword',
  send: 'send',
  setNewPassword: 'setNewPassword',
  copyToSite: 'copyToSite',
  minRequiredVisitDuration: 'minRequiredVisitDuration',
  status: 'status',
  useLastVisitStatus: 'useLastVisitStatus',
  before: 'before',
  after: 'after',
  to: 'to',
  taskType: 'taskType',
  assignedAssets: 'assignedAssets',
  changeDate: 'changeDate',
  calendarInfo: 'calendarInfo',
  calendarTypes: 'calendarTypes',
  asset: 'asset',
  alertsAndAreasOfInterest: 'alertsAndAreasOfInterest',
  device: 'device',
  report: 'report',
  types: 'types',
  accumulatePrivateDistance: 'accumulatePrivateDistance',
  messagingStatus: 'messagingStatus',
  frameRetention: 'frameRetention',
  uploadIcon: 'uploadIcon',
  driverSettings: 'driverSettings',
  driverIdentification: 'driverIdentification',
  logoutOnStop: 'logoutOnStop',
  hidePosition: 'hidePosition',
  hideFuelLevelAndRefuelling: 'hideFuelLevelAndRefuelling',
  hideTripInformation: 'hideTripInformation',
  measurementMode: 'measurementMode',
  digitalInput: 'digitalInput',
  workTimeWhenSignalHigh: 'workTimeWhenSignalHigh',
  from: 'from',
  service: 'service',
  imeiHistory: 'imeiHistory',
  deviceLocation: 'deviceLocation',
  useDefaultLocation: 'useDefaultLocation',
  deviceSendsLocation: 'deviceSendsLocation',
  years: 'years',
  year: 'year',
  months: 'months',
  days: 'days',
  holidayEntries: 'holidayEntries',
  useForIndefiniteTime: 'useForIndefiniteTime',
  notOlderThan: 'notOlderThan',
  notOlderThanDays: 'notOlderThanDays',
  notNewerThan: 'notNewerThan',
  acceptFramesOutOfRangeAndUseServerTime: 'acceptFramesOutOfRangeAndUseServerTime',
  refuseFramesOutOfRange: 'refuseFramesOutOfRange',
  timeRange: 'timeRange',
  handlingMode: 'handlingMode',
  handlingWrongFramesTime: 'handlingWrongFramesTime',
  other: 'other',
  otherConfiguration: 'otherConfiguration',
  acceptFrames: 'acceptFrames',
  ignorePositionWhenHdopGreaterThan: 'ignorePositionWhenHdopGreaterThan',
  waitingTimeForBufferedFrames: 'waitingTimeForBufferedFrames',
  vehicleIsRunningWhen: 'vehicleIsRunningWhen',
  rejectIncorrectPositionWhenSpeedGreaterThan: 'rejectIncorrectPositionWhenSpeedGreaterThan',
  ignition: 'ignition',
  ignitionIsOn: 'ignitionIsOn',
  speedIsGreaterThanOrEqualTo: 'speedIsGreaterThanOrEqualTo',
  ignitionIsOnOrSpeedIsGreaterOrEqualTo: 'ignitionIsOnOrSpeedIsGreaterOrEqualTo',
  rpmIsGreaterOrEqualTo: 'rpmIsGreaterOrEqualTo',
  isNotInPlaceOfRadius: 'isNotInPlaceOfRadius',
  deviceVoltageIsGreaterOrEqualTo: 'deviceVoltageIsGreaterOrEqualTo',
  batteryVoltageIsGreaterOrEqualTo: 'batteryVoltageIsGreaterOrEqualTo',
  occursLongerThan: 'occursLongerThan',
  value: 'value',
  privateTimeAccordingToCalendar: 'privateTimeAccordingToCalendar',
  privateTimeAccordingToSwitch: 'privateTimeAccordingToSwitch',
  identificationMode: 'identificationMode',
  libraryManagement: 'libraryManagement',
  measurement: 'measurement',
  distanceMeter: 'distanceMeter',
  distanceMeterMode: 'distanceMeterMode',
  adjustToDistanceMeterReadout: 'adjustToDistanceMeterReadout',
  gps: 'gps',
  can: '',
  finish: 'finish',
  adjustment: 'adjustment',
  dateAndTimeReadout: 'dateAndTimeReadout',
  mileageReadout: 'mileageReadout',
  apply: 'apply',
  text: 'text',
  fontSize: 'fontSize',
  chooseAnIcon: 'chooseAnIcon',
  temporaryIcons: 'temporaryIcons',
  serviceMeterMode: 'serviceMeterMode',
  serviceMeter: 'serviceMeter',
  adjustToServiceMeterReadout: '',
  engine: 'engine',
  isIdleRpm: 'isIdleRpm',
  isNormalRpm: 'isNormalRpm',
  isIdleSpeed: 'isIdleSpeed',
  speed: 'speed',
  nominalRpm: 'nominalRpm',
  combinationMode: 'combinationMode',
  operator: 'operator',
  rpm: 'rpm',
  digitalInputsCount: 'digitalInputsCount',
  analogInputsCount: 'analogInputsCount',
  eventKeywordsCount: 'eventKeywordsCount',
  libraryName: 'libraryName',
  libraryType: 'libraryType',
  mappings: 'mappings',
  digitalInputMapping: 'digitalInputMapping',
  digitalInputName: 'digitalInputName',
  digitalInputValue: 'digitalInputValue',
  on: 'On',
  off: 'Off',
  skipReadoutsWhenIgnitionOff: 'skipReadoutsWhenIgnitionOff',
  analogInputMapping: 'analogInputMapping',
  analogInputName: 'analogInputName',
  analogInputValue: 'analogInputValue',
  correspondTo: 'correspondTo',
  readingMode: 'readingMode',
  eventKeywordsMapping: 'eventKeywordsMapping',
  keywordName: 'keywordName',
  keywordString: 'keywordString',
  devicesAssignment: 'devicesAssignment',
  thisSiteDoesntExist: 'thisSiteDoesntExist',
  libraryDefinition: 'libraryDefinition',
  libraryDetails: 'libraryDetails',
  eventKeywordMapping: 'eventKeywordMapping',
  variablesList: 'variablesList',
  variablesTypes: 'variablesTypes',
  source: 'source',
  redirectToYourDefaultSite: 'redirectToYourDefaultSite',
  keepPreviousReading: 'keepPreviousReading',
  pleaseAddDriverBeforeAddingNewLibrary: 'pleaseAddDriverBeforeAddingNewLibrary',
  modified: 'modified',
  statusAndAlarmDefinitions: 'statusAndAlarmDefinitions',
  definition: 'definition',
  assetAssignment: 'assetAssignment',
  handling: 'handling',
  validity: 'validity',
  dashboardConfiguration: 'dashboardConfiguration',
  formula: 'formula',
  always: 'always',
  custom: 'custom',
  basedOnCalendarTemplate: 'basedOnCalendarTemplate',
  autoReadAfter: 'autoReadAfter',
  occurrenceType: 'occurrenceType',
  sendNotificationWhenOver: 'sendNotificationWhenOver',
  icon: 'icon',
  color: 'color',
  image: 'image',
  threatBoard: 'threatBoard',
  treatmentMode: 'treatmentMode',
  reatedAfterOnePersonMarkItWithRead: 'reatedAfterOnePersonMarkItWithRead',
  reatedAfterEveryUserMarkItWithRead: 'reatedAfterEveryUserMarkItWithRead',
  assigned: 'assigned',
  previous: 'previous',
  popupMode: 'popupMode',
  showPopup: 'showPopup',
  validityType: 'validityType',
  alarmType: 'alarmType',
  startFormula: 'startFormula',
  endFormula: 'endFormula',
  resolveAlarmWhenReadBy: 'resolveAlarmWhenReadBy',
  timeSpan: 'timeSpan',
  addFormula: 'addFormula',
  isEndFormulaActive: 'isEndFormulaActive',
  inputName: 'inputName',
  inputMappingType: 'inputMappingType',
  systemType: 'systemType',
  sourceType: 'sourceType',
  OneTimeOnly: 'OneTimeOnly',
  Continous: 'Continous',
  AnyPerson: 'AnyPerson',
  EntireGroup: 'EntireGroup',
  Custom: 'AnyPerson',
  CalendarTemplate: 'EntireGroup',
  buttonsLibrary: 'buttonsLibrary',
  author: 'author',
  libraryButtons: 'libraryButtons',
  deleteCategory: 'deleteCategory',
  renameCategory: 'renameCategory',
  addButton: 'addButton',
  configureButton: 'configureButton',
  baseLayers: 'baseLayers',
  normal: 'normal',
  satelite: 'satelite',
  terrain: 'terrain',
  traffic: 'traffic',
  overlayLayers: 'overlayLayers',
  truckRestriction: 'truckRestriction',
  map: 'map',
  priority: 'priority',
  highPriority: 'highPriority',
  size: 'size',
  small: 'small',
  medium: 'medium',
  large: 'large',
  single: 'single',
  multi: 'multi',
  category: 'category',
  categoryName: 'categoryName',
  sensitiveButton: 'sensitiveButton',
  commentRequired: 'commentRequired',
  reasonComments: 'reasonComments',
  reasonComment: 'reasonComment',
  command: 'command',
  commandText: 'commandText',
  state: 'state',
  delete: 'delete',
  addCategory: 'addCategory',
  selectLibraryTemplate: 'selectLibraryTemplate',
  allLibraries: 'allLibraries',
  loadLibrary: 'loadLibrary',
  noButtonSelected: 'noButtonSelected',
  noButtonLibrarySelected: 'noButtonLibrarySelected',
  deleteCategoryAndButtons: 'deleteCategoryAndButtons',
  deleteCategoryAndMoveButtons: 'deleteCategoryAndMoveButtons',
  defaultConfirmDialogMessage: 'defaultConfirmDialogMessage',
  defaultConfirmDialogTitle: 'defaultConfirmDialogTitle',
  pleaseSwitchToGeneralTabAndSelectDeviceDriverAndLibraryFirst:
    'pleaseSwitchToGeneralTabAndSelectDeviceDriverAndLibraryFirst',
  dateToCannotBeEarlierThanDateFrom: 'dateToCannotBeEarlierThanDateFrom',
  alarmTracking: 'alarmTracking',
  prefix: 'prefix',
  inOut: 'inOut',
  eventName: 'eventName',
  localization: 'localization',
  action: 'action',
  activeOnly: 'activeOnly',
  in: 'in',
  out: 'out',
  datePeriod: 'datePeriod',
  goToHomePage: 'goToHomePage',
  doNotSave: 'doNotSave',
  maxSizeIs: 'maxSizeIs',
  uploadImage: 'uploadImage',
  removeImage: 'removeImage',
  alarmStop: 'alarmStop',
  alarmStart: 'alarmStart',
  imagesForAssetsStatus: 'imagesForAssetsStatus',
  loadYourImage: 'loadYourImage',
  load: 'load',
  pleaseSelectDevice: 'pleaseSelectDevice',
  automaticCommands: 'automaticCommands',
  sendAutomaticCommands: 'sendAutomaticCommands',
  addCommands: 'addCommands',
  deleteCommands: 'deleteCommands',
  timeLapseToTrigger: 'timeLapseToTrigger',
  selectAsset: 'selectAsset',
  selectAll: 'selectAll',
  noResults: 'noResults',
  logicalOR: 'logicalOR',
  logicalAND: 'logicalAND',
  width: 'width',
  lastReceivedDate: 'lastReceivedDate',
  addText: 'addText',
  drawIcon: 'drawIcon',
  drawPolyline: 'drawPolyline',
  drawPolygon: 'drawPolygon',
  accepted: 'accepted',
  alarmName: 'alarmName',
  affectedAlarms: 'affectedAlarms',
  assetStatus: 'assetStatus',
  trigger: 'trigger',
  commandType: 'commandType',
  technicalArchive: 'technicalArchive',
  time: 'time',
  eventKeyword: 'eventKeyword',
  showRawMessage: 'showRawMessage',
  showTranslatedMessage: 'showTranslatedMessage',
  showAll: 'showAll',
  showAddress: 'showAddress',
  showGeocode: 'showGeocode',
  unread: 'unread',
  devicesGroups: 'devicesGroups',
  groupName: 'groupName',
  hasBeenTriggered: 'hasBeenTriggered',
  createdAt: 'createdAt',
  lastHour: 'lastHour',
  last15Minutes: 'last15Minutes',
  backToTracking: 'backToTracking',
  displayAlwaysOnFeed: 'displayAlwaysOnFeed',
  notificationPreview: 'notificationPreview',
  addToFavourites: 'addToFavourites',
  removeFromFavourites: 'removeFromFavourites',
  assign: 'assign',
  archive: 'archive',
  reload: 'reload',
  unassignAssetFromAlarm: 'unassignAssetFromAlarm',
  deactivateAlarm: 'deactivateAlarm',
  notificationsHistory: 'notificationsHistory',
  accessDenied: 'accessDenied',
  search: 'search',
  siteNotification: 'siteNotification',
  systemNotification: 'systemNotification',
  emailNotifications: 'emailNotifications',
  exportToCSV: 'exportToCSV',
  exportToPDF: 'exportToPDF',
  previewPDFFile: 'previewPDFFile',
  resourceManagement: 'resourceManagement',
  page: 'page',
  exportedBy: 'exportedBy',
  saveOpenCriteria: 'saveOpenCriteria',
  enterCriteriaName: 'enterCriteriaName',
  add: 'add',
  showAllMessages: 'showAllMessages',
  showIncomingMessages: 'showIncomingMessages',
  showOutgoingMessages: 'showOutgoingMessages',
  reason: 'reason',
  deliveryStatus: 'deliveryStatus',
  delivered: 'delivered',
  undelivered: 'undelivered',
  sent: 'sent',
  beingSent: 'beingSent',
  failed: 'failed',
  messageType: 'messageType',
  incomingMessages: 'incomingMessages',
  outgoingMessages: 'outgoingMessages',
  only2kEntriesAreDisplayed: 'only2kEntriesAreDisplayed',
  buttonWillBeEnabledAfter: 'buttonWillBeEnabledAfter',
  secs: 'secs',
  customization: 'Customization',
  siteConfiguration: 'Site Configuration',
  assignToUser: 'assignToUser',
  menu: 'menu',
  corridor: 'corridor',
  done: 'done',
  lastPing: 'lastPing',
  otherComment: 'otherComment',
  submit: 'submit',
  unassignHolidayTemplateFromCalendar: 'unassignHolidayTemplateFromCalendar',
  systemMappingType: 'systemMappingType',
  viewType: 'viewType',
  reportAProblem: 'reportAProblem',
  deleteAlarm: 'deleteAlarm',
  limitSearchCriteria1k: 'limitSearchCriteria1k',
  interactiveMaps: 'interactiveMaps',
  onlyPopup: 'onlyPopup',
  addToThreatBoard: 'addToThreatBoard',
  statusHistory: 'statusHistory',
  actionButtons: 'actionButtons',
  zoomIn: 'zoomIn',
  zoomOut: 'zoomOut',
  fillColor: 'fillColor',
  analogMappings: 'analogMappings',
  digitalMappings: 'digitalMappings',
  keywordMappings: 'keywordMappings',
  timeMappings: 'timeMappings',
  systemMappings: 'systemMappings',
  httpFeed: 'httpFeed',
  clickMapToPlaceMarker: 'clickMapToPlaceMarker',
  webhooks: 'webhooks',
  translateAddressUpToXDevices: 'translateAddressUpToXDevices',
};

export default LabelsLocaleKeys;
