import React, { useState, useCallback, useEffect } from 'react';
import { styled } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { RackPlaceholderItem } from './RackPlaceholderItem';
import { TopSection } from './TopSection';
import { RackItem } from './RackItem';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { update, updateCompartments, addCompartments } from 'redux/assets/actions';
import { getLoadingAssets, getCompartments } from 'redux/assets/selectors';
import ErrorMessage from 'Components/display/ErrorMessage';
import Loader from 'Components/Loader';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { CancelToken, isCancel } from 'Api/ApiClient';
import { getDevicesDashboardCompartments } from 'Api/devices';
import { withSignalR } from 'context/signalr';

import _ from 'lodash';

const Wrapper = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const RowWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  paddingBottom: 8,
  paddingLeft: 4,
  paddingRight: 4,
});

export const GeckoAssetStatus = ({
  alarm,
  device,
  updateCompartments,
  addCompartments,
  loadingAssets,
  compartments,
  currentSiteId,
  hub,
}) => {
  const assetId = device?.externalId;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [retryFlag, setRetryFlag] = useState(false);

  const getRackRows = ({ compartments }) => {
    const chunkSize = 4;
    const initialChunkSize = 3;
    const groups = [...compartments];

    const firstGroup = groups.splice(groups, initialChunkSize);
    const placeholderIndex = firstGroup.findIndex((item) => item.number === 2) + 1;

    firstGroup.splice(placeholderIndex, 0, { value: 'placeholder', name: 'placeholder' });

    const rest = _.chunk(groups, chunkSize);

    return [firstGroup, ...rest];
  };

  const fetchData = useCallback(
    (assetId, cancelToken) => {
      setLoading(true);
      setError(null);

      getDevicesDashboardCompartments(assetId, null, { cancelToken })
        .then((response) => {
          const newCompartments = response.data;

          if (newCompartments?.length > 0) {
            addCompartments({
              externalId: assetId,
              compartments: newCompartments,
            });
          }

          setLoading(false);
        })
        .catch((error) => {
          if (!isCancel(error)) {
            setError(error);
            setLoading(false);
          }
        });
    },
    [addCompartments]
  );

  useEffect(() => {
    const source = CancelToken.source();

    if (assetId && !loadingAssets) {
      fetchData(assetId, source.token);
    } else {
      setLoading(false);
    }

    return () => {
      if (assetId) {
        source.cancel('"assetdId" changed or component unmounted');
      }
    };
  }, [assetId, fetchData, retryFlag, loadingAssets]);

  useEffect(() => {
    const handleOnAlarm = ({
      assetId: newAlarmAssetId,
      alarmName,
      compartmentNo,
      compartmentRfId,
      compartmentStatus,
      tenantId,
    }) => {
      if (newAlarmAssetId === assetId && currentSiteId === tenantId) {
        if (compartmentNo) {
          updateCompartments({
            externalId: newAlarmAssetId,
            compartments: [
              {
                externalId: newAlarmAssetId,
                keyword: alarmName,
                message: alarmName,
                number: compartmentNo,
                rfId: compartmentRfId,
                state: compartmentStatus,
                tenantId,
              },
            ],
          });
        }
      }
    };

    const handleOnAlarms = ({ alarms: newAlarms, tenantId }) => {
      if (tenantId === currentSiteId) {
        let compartmentsToUpdate = [];

        compartmentsToUpdate.push(...newAlarms.filter((alarm) => alarm.assetId === assetId));

        compartmentsToUpdate = compartmentsToUpdate.map(
          ({ assetId, alarmName, compartmentNo, compartmentRfId, compartmentStatus, tenantId }) => ({
            externalId: assetId,
            keyword: alarmName,
            message: alarmName,
            number: compartmentNo,
            rfId: compartmentRfId,
            state: compartmentStatus,
            tenantId,
          })
        );

        if (compartmentsToUpdate?.length > 0) {
          updateCompartments({
            externalId: assetId,
            compartments: compartmentsToUpdate,
          });
        }
      }
    };

    if (hub) {
      hub.on('Alarm', handleOnAlarm);
      hub.on('Alarms', handleOnAlarms);
    }

    return () => {
      if (hub) {
        hub.off('Alarm', handleOnAlarm);
        hub.off('Alarms', handleOnAlarms);
      }
    };
  }, [hub, assetId, currentSiteId]);

  if (loading || loadingAssets || compartments.length <= 0) {
    return <Loader />;
  }

  if (error) {
    return (
      <ErrorMessage
        primaryMessage={T.translate(LocaleKeys.messages.errorWhileFetchingData, {
          name: T.translate(LocaleKeys.labels.status),
        })}
        secondaryMessage={T.translate(LocaleKeys.messages.clickRefreshToTryAgain)}
        onRetry={() => setRetryFlag((prev) => !prev)}
      />
    );
  }

  const compartmentsRows = getRackRows({ compartments });

  return (
    <Wrapper>
      <TopSection alarm={alarm} device={device} />

      <Box p={2} width="976px">
        {compartmentsRows?.map((row, index) => (
          <RowWrapper key={index}>
            {row.map((rackItem) => {
              if (rackItem.value === 'placeholder') {
                return <RackPlaceholderItem key={rackItem.number} />;
              }

              return (
                <RackItem
                  key={rackItem.number}
                  message={rackItem.message}
                  rfId={rackItem.rfId}
                  compartmentState={rackItem.state}
                />
              );
            })}
          </RowWrapper>
        ))}
      </Box>
    </Wrapper>
  );
};

const mapState = (state) => ({
  currentSiteId: state.sites.currentSiteId,
  loadingAssets: getLoadingAssets(state),
  compartments: getCompartments(state),
});

const mapDispatch = {
  update,
  updateCompartments,
  addCompartments,
};

export default compose(connect(mapState, mapDispatch), withSignalR)(GeckoAssetStatus);
