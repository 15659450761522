import React from 'react';
import ExpansionSection from 'Components/display/ExpansionPanel/ExpansionSection';
import { withRouter } from 'react-router-dom';

import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import withPermissions from 'hocs/withPermissions';

import { CircularProgress, Box, styled, Paper, Typography } from '@material-ui/core';

import { Permissions } from 'Constants/permissions';

import { CurrentlySelectedItems } from './alarmsAndStatuses/CurrentlySelectedItems';

import { compose } from 'redux';
import { AlarmGroupFilters } from './alarmsAndStatuses/AlarmGroupFilters';
import ListInput from './alarmsAndStatuses/ListInput';
import { useAlarmsAndStatuses } from './alarmsAndStatuses/useAlarmsAndStatuses';

const Container = styled(Paper)({
  width: 'fit-content',
  minHeight: '800px',
  height: 'calc(100% - 24px)',
  overflow: 'hidden',
  margin: 10,
});

const NotSelectedAlarmGroupContainer = styled(Box)({
  width: 450,
  padding: 12,
  display: 'flex',
  justifyContent: 'center',
});

const ListInputWrapper = styled('div')({
  height: 'calc(100% - 64px)' /* this height is required for ListInput to render properly*/,
});

const AlarmsForm = styled('form')({
  height: 'inherit',
});

export const AlarmsAndStatuses = (props) => {
  const { alarmGroups, alarms, selectedAlarmGroup, onItemClick, onChange, setSelectedAlarmGroup } =
    useAlarmsAndStatuses(props);

  if (!alarmGroups) {
    return <CircularProgress />;
  }

  return (
    <ExpansionSection data={[]}>
      <Box display="flex" flexDirection="row" width="100%">
        <AlarmGroupFilters
          alarmGroups={alarmGroups}
          onGroupClick={setSelectedAlarmGroup}
          selectedAlarmGroup={selectedAlarmGroup}
        />
        <Box flex={2}>
          <Box height="100%">
            <AlarmsForm onSubmit={props.handleSubmit}>
              <Box display="flex" flexDirection="row" justifyContent="flex-start" height="inherit">
                <Container>
                  <ListInputWrapper>
                    {alarms ? (
                      <ListInput
                        selected={props?.values?.selectedAlarms?.map(({ id }) => id)}
                        items={alarms}
                        disabled={!props.deviceExternalId}
                        onChange={onChange}
                        onItemClick={onItemClick}
                        valueField="id"
                        displayField="name"
                        label={T.translate(LocaleKeys.labels.alarms).toUpperCase()}
                        width={'450px'}
                      />
                    ) : (
                      <NotSelectedAlarmGroupContainer>
                        <Typography>{T.translate(LocaleKeys.messages.selectOneOfGroup)}</Typography>
                      </NotSelectedAlarmGroupContainer>
                    )}
                  </ListInputWrapper>
                </Container>
                <CurrentlySelectedItems selectedAlarms={props.values.selectedAlarms} onChange={props.handleChange} />
              </Box>
            </AlarmsForm>
          </Box>
        </Box>
      </Box>
    </ExpansionSection>
  );
};

export const AlarmsAndStatusesNew = compose(
  withRouter,
  withPermissions([Permissions.CanAddAlarmGroup, Permissions.CanEditAlarmGroup])
)(AlarmsAndStatuses);
export const AlarmsAndStatusesDetails = compose(
  withRouter,
  withPermissions([Permissions.CanAddAlarmGroup, Permissions.CanEditAlarmGroup])
)(AlarmsAndStatuses);
