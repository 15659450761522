import * as React from 'react';

export const RackPlaceholderItem = (props) => (
  <svg width={224} height={78} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path fill="#F7F7F7" d="M215.5 0H8.5a8 8 0 0 0-8 8v62a8 8 0 0 0 8 8h207a8 8 0 0 0 8-8V8a8 8 0 0 0-8-8Z" />
      <g clipPath="url(#b)">
        <path fill="#B1B5BD" d="M124 12H17a4 4 0 0 0-4 4v46a4 4 0 0 0 4 4h107a4 4 0 0 0 4-4V16a4 4 0 0 0-4-4Z" />
      </g>
      <g clipPath="url(#c)">
        <path
          fill="#B1B5BD"
          d="M188 19h-20c-11.046 0-20 8.954-20 20s8.954 20 20 20h20c11.046 0 20-8.954 20-20s-8.954-20-20-20Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.5 0h223v78H.5z" />
      </clipPath>
      <clipPath id="b">
        <path fill="#fff" d="M13 12h115v54H13z" />
      </clipPath>
      <clipPath id="c">
        <path fill="#fff" d="M148 19h60v40h-60z" />
      </clipPath>
    </defs>
  </svg>
);

export default RackPlaceholderItem;
