import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import DeviceIcon from 'Components/display/DeviceIcon';
import { Warning } from '@material-ui/icons';
import { NotificationStatuses } from 'Constants/NotificationStatuses';
import { AlarmTypes } from 'Constants/AlarmTypes';
import ErrorIcon from '@material-ui/icons/Error';
import EmptyIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import FullIcon from './FullIcon';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ClosedIcon from '@material-ui/icons/Lock';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';

const TopSectionWrapper = styled('div')({
  height: 80,
  paddingLeft: 10,
  paddingRight: 10,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid #E5E6E9',
});
const TitleWrapper = styled('div')({
  display: 'flex',
});
const TitleTextWrapper = styled('div')({
  marginLeft: 8,
});
const DeviceName = styled(Typography)({
  fontWeight: 900,
});

const Legend = styled('div')({ display: 'flex', justifyContent: 'space-around', width: 258 });
const LegendColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
});
const LegendText = styled(Typography)({
  fontWeight: 900,
  cursor: 'default',
});
const LegendField = styled('div')({
  display: 'flex',
  alignItems: 'center',
  cursor: 'default',
  minWidth: 60,
});
const LegendFieldTitle = styled(Typography)({
  fontWeight: 900,
  marginLeft: 8,
  textTransform: 'capitalize',
});
const DateWrapper = styled('div')({});

const WarningIcon = styled(Warning)(({ alarmType }) => ({
  fill: getColor(alarmType),
  width: 36,
  height: 36,
}));

const VolumeUpIcon = styled(Warning)(({ alarmType }) => ({
  width: 24,
  height: 24,
}));

export const getColor = (type) => {
  switch (type) {
    case AlarmTypes.ALARM:
      return '#d9001b';
    case AlarmTypes.STATUS:
      return '#F59A22';
    default:
      return '#FFF';
  }
};

export const TopSection = ({ device, alarm }) => {
  const alarmObject = alarm || device?.lastMessage;
  const alarmCreatedOn = alarmObject?.createdAt;
  const hideIcon = alarmObject && alarmObject.alarmStatus === NotificationStatuses.CLEARED;
  const showSoundIcon = alarmObject?.isSoundEnabled;

  return (
    <TopSectionWrapper>
      <TitleWrapper>
        <DeviceIcon iconId={device?.iconId} color={device?.color} />
        {!!alarmObject && (
          <Box display="flex" alignItems="center" alignSelf="center" ml={2}>
            <WarningIcon visibility={hideIcon ? 'hidden' : 'visible'} alarmType={alarmObject?.alarmType} />
            <VolumeUpIcon visibility={showSoundIcon ? 'visible' : 'hidden'} />
          </Box>
        )}
        <TitleTextWrapper>
          <Typography>{device?.name}</Typography>
          <DeviceName variant="body1">{alarmObject?.message || device?.name}</DeviceName>
        </TitleTextWrapper>
      </TitleWrapper>

      <Legend>
        <LegendColumn>
          <LegendText variant="body2">{T.translate(LocaleKeys.labels.legend)}</LegendText>
          <LegendField>
            <ErrorIcon style={{ width: 16 }} />
            <LegendFieldTitle variant="caption">{T.translate(LocaleKeys.labels.error)}</LegendFieldTitle>
          </LegendField>
        </LegendColumn>
        <LegendColumn>
          <LegendField>
            <FullIcon />
            <LegendFieldTitle variant="caption">{T.translate(LocaleKeys.labels.full)}</LegendFieldTitle>
          </LegendField>
          <LegendField>
            <EmptyIcon style={{ width: 16 }} />
            <LegendFieldTitle variant="caption">{T.translate(LocaleKeys.labels.empty)}</LegendFieldTitle>
          </LegendField>
        </LegendColumn>
        <LegendColumn>
          <LegendField>
            <LockOpenIcon style={{ width: 16 }} />
            <LegendFieldTitle variant="caption">{T.translate(LocaleKeys.labels.open)}</LegendFieldTitle>
          </LegendField>
          <LegendField>
            <ClosedIcon style={{ width: 16 }} />
            <LegendFieldTitle variant="caption">{T.translate(LocaleKeys.labels.closed)}</LegendFieldTitle>
          </LegendField>
        </LegendColumn>
      </Legend>

      <DateWrapper>
        <Typography>{(alarmCreatedOn && new Date(alarmCreatedOn).toLocaleString()) || ''}</Typography>
      </DateWrapper>
    </TopSectionWrapper>
  );
};
