import React from 'react';
import { AlarmGroupItem } from './AlarmGroupItem';
import { Box, styled, Typography } from '@material-ui/core';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';

const FiltersSection = styled(Box)({
  width: 450,
  display: 'flex',
  flexDirection: 'column',

  margin: 12,
});
const SectionTitle = styled(Typography)({
  marginBottom: 12,
  margin: 12,
  textAlign: 'center',
});

export const AlarmGroupFilters = ({ alarmGroups, onGroupClick, selectedAlarmGroup }) => {
  return (
    <FiltersSection>
      <SectionTitle variant="body1"> {T.translate(LocaleKeys.labels.alarmGroups)}</SectionTitle>

      {alarmGroups.map((group) => (
        <AlarmGroupItem
          key={group.name}
          group={group}
          onClick={onGroupClick}
          selected={selectedAlarmGroup?.externalId === group?.externalId}
        />
      ))}
    </FiltersSection>
  );
};
