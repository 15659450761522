import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import ActionButtonsWidget from 'Components/display/ActionButtonsWidget';
import ApiIconMarker from 'Components/ApiIconMarker';
import AssetStatus from './AssetStatus';
import DeviceSidebarView from '../../../Dashboard/DeviceSidebarView';
import HistoryWidget from './history';
import LeafletMap from 'Components/LeafletMap';
import { withConfigurationStoreLayers } from 'Components/LeafletMap/hocs/withConfigurationStoreLayers';
import WidgetPanel from 'Components/display/WidgetPanel';
import { Permissions } from 'Constants/permissions';
import ThreatBoard from './ThreatBoard';
import { addThreatBoardDevice } from 'redux/assets/actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withPermissions from 'hocs/withPermissions';
import { useConfigurationStore } from 'hooks/useConfigurationStore';
import { useSelectedAddressesList } from 'hooks/useSelectedAddressesList';
import { getThreatBoardAlarm, getDevice } from 'redux/assets/selectors';
import AssetNames from 'Components/LeafletMap/controls/AssetNames';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { SIDEBAR_STATE_NAME } from './constants';
import GeckoAssetStatus from './GeckoAssetStatus/GeckoAssetStatus';

const getInitialFromDate = () => {
  const date = new Date();

  date.setHours(0, 0, 0);

  return date.toISOString();
};

const defaultUserSettings = {
  historyWidgetExpanded: false,
  selectedDevice: null,
  openedBtnCategoryIds: [],
  deviceFilters: [],
  params: {
    activeChecked: false,
    from: getInitialFromDate(),
    inChecked: true,
    outChecked: true,
    search: '',
    to: null,
    historyWidgetExpanded: false,
  },
};

const transformFiltersDateRangeToDefault = (userSettings) => ({
  ...userSettings,
  deviceFilters: userSettings.deviceFilters.map((item) => ({
    ...item,
    params: {
      ...item.params,
      from: getInitialFromDate(),
      to: null,
    },
  })),
  params: {
    ...defaultUserSettings.params,
  },
});

const getInitialUserSettings = () => {
  const storedUserSettings = JSON.parse(localStorage.getItem('userSettings')) || defaultUserSettings;

  return transformFiltersDateRangeToDefault(storedUserSettings);
};

const LeafletMapWithConfigurationStore = withConfigurationStoreLayers(LeafletMap);

const Content = ({ alarm, device, children }) => children(alarm, device);
const mapState = (state, ownProps) => {
  const alarm = getThreatBoardAlarm(state, ownProps.alarm?.alarmId, ownProps.alarm?.assetId);

  return {
    alarm,
    device: getDevice(state, alarm ? alarm.assetId : ownProps.selectedAssetId),
  };
};
const ContentConnected = connect(mapState)(Content);

const AlarmTracking = ({ currentSiteId, addThreatBoardDevice, permissions }) => {
  const [userSettings, setUserSettings] = useState(getInitialUserSettings);
  const [selectedAssetIds, setSelectedAssetIds] = useState([userSettings.selectedDevice] || []);
  const [selectedThreatBoardAlarm, setSelectedThreatBoardAlarm] = useState(null);
  const [showAssetNames, setShowAssetNames] = useConfigurationStore(`${SIDEBAR_STATE_NAME}-showAssetNames`, false);
  const { rowsWithVisibleAddressIds, handleSelectedAddressesListChange } = useSelectedAddressesList({
    configurationStoreName: SIDEBAR_STATE_NAME,
  });

  useEffect(() => {
    if (selectedAssetIds[0]) {
      setSelectedThreatBoardAlarm(null);
    }
  }, [selectedAssetIds]);

  const updateUserSettings = (settings) => {
    const updatedSettings = { ...userSettings, ...settings };

    setUserSettings(updatedSettings);

    localStorage.setItem('userSettings', JSON.stringify(updatedSettings));
  };

  const handleBtnCategoryExpandChange = (categoryId) => (event, isExpanded) => {
    updateUserSettings({
      openedBtnCategoryIds: isExpanded
        ? [...userSettings.openedBtnCategoryIds, categoryId]
        : userSettings.openedBtnCategoryIds.filter((catId) => catId !== categoryId),
    });
  };

  const handleClickAlarm = (alarm) => {
    setSelectedThreatBoardAlarm(alarm);
    setSelectedAssetIds([]);
  };
  const handleClickDevice = (assetId) => {
    setSelectedThreatBoardAlarm(null);
    setSelectedAssetIds([assetId]);
    updateUserSettings({ selectedDevice: assetId });
  };

  useEffect(() => {
    updateUserSettings({
      ...transformFiltersDateRangeToDefault(userSettings),
      selectedDevice: selectedAssetIds[0] || null,
    });
    /* eslint-disable-next-line */
  }, [selectedAssetIds]);

  const getFilterSettingsForDevice = () => {
    if (selectedAssetIds[0]) {
      return userSettings?.deviceFilters?.find((dev) => dev.id === selectedAssetIds[0])?.params || userSettings.params;
    }

    return userSettings.params;
  };

  const saveFilterSettingsForDevice = (device) => {
    const filters = userSettings?.deviceFilters?.filter((dev) => dev.id !== device.id) || [];

    updateUserSettings({
      deviceFilters: [
        ...filters,
        {
          id: device.id,
          params: {
            ...device.params,
            historyWidgetExpanded: getFilterSettingsForDevice().historyWidgetExpanded,
          },
        },
      ],
    });
  };

  const saveHistoryWidgetForDevice = (state) => {
    if (selectedAssetIds[0]) {
      const settingsForSelectedDevice = userSettings?.deviceFilters?.find((dev) => dev.id === selectedAssetIds[0]);

      if (settingsForSelectedDevice) {
        const filters = userSettings?.deviceFilters?.filter((dev) => dev.id !== selectedAssetIds[0]) || [];

        updateUserSettings({
          deviceFilters: [
            ...filters,
            {
              id: selectedAssetIds[0],
              params: {
                ...settingsForSelectedDevice.params,
                historyWidgetExpanded: state,
              },
            },
          ],
        });
      } else {
        updateUserSettings({
          deviceFilters: [
            ...userSettings.deviceFilters,
            {
              id: selectedAssetIds[0],
              params: {
                ...userSettings.params,
                historyWidgetExpanded: state,
              },
            },
          ],
        });
      }
    }
  };

  return (
    <>
      <ThreatBoard onClickAlarm={handleClickAlarm} onClickDevice={handleClickDevice} />
      <Box display={'flex'} height={'calc(100vh - 310px)'}>
        <DeviceSidebarView
          selectedAssetIds={selectedAssetIds}
          setSelectedAssetIds={setSelectedAssetIds}
          rowsWithVisibleAddressIds={rowsWithVisibleAddressIds}
          setRowsWithVisibleAddressIds={handleSelectedAddressesListChange}
          displayBackToDashboardButton
          options={{
            sidebarStateName: SIDEBAR_STATE_NAME,
            contextMenuItems: [
              { label: T.translate(LocaleKeys.labels.addToThreatBoard), function: addThreatBoardDevice },
            ],
            trail: true,
          }}
        >
          {(devices, selectedAssets, deviceTrail) => {
            return (
              <ContentConnected alarm={selectedThreatBoardAlarm} selectedAssetId={selectedAssets[0]}>
                {(alarm, selectedDevice) => {
                  const isGecko = selectedDevice?.driverName === 'Gecko';

                  return (
                    <>
                      <Box display="flex" flexDirection="column" flexGrow={1} height={'100%'}>
                        {isGecko ? (
                          <WidgetPanel title={T.translate(LocaleKeys.labels.assetStatus)} margin={'2'}>
                            <GeckoAssetStatus alarm={alarm} device={selectedDevice} />
                          </WidgetPanel>
                        ) : (
                          <WidgetPanel title={T.translate(LocaleKeys.labels.assetStatus)} margin={'2'}>
                            <AssetStatus alarm={alarm} device={selectedDevice} />
                          </WidgetPanel>
                        )}

                        <Box>
                          <WidgetPanel
                            defaultExpanded={getFilterSettingsForDevice().historyWidgetExpanded}
                            expandable
                            overflow={'auto'}
                            title={T.translate(LocaleKeys.labels.statusHistory)}
                            height="400px"
                            onExpandChange={(expanded) => saveHistoryWidgetForDevice(expanded)}
                          >
                            <HistoryWidget
                              device={selectedDevice}
                              userSettings={getFilterSettingsForDevice()}
                              updateUserSettings={saveFilterSettingsForDevice}
                              isExpanded={getFilterSettingsForDevice().historyWidgetExpanded}
                            />
                          </WidgetPanel>
                        </Box>
                      </Box>
                      <Box display={'flex'} flexDirection={'column'} marginLeft={2} height={'100%'} width={'420px'}>
                        <Box>
                          <WidgetPanel title={T.translate(LocaleKeys.labels.map)} contentPadding="0">
                            <Box height={'350px'} width={'100%'} position="relative">
                              <LeafletMapWithConfigurationStore
                                configurationStoreKey={`${SIDEBAR_STATE_NAME}-layers`}
                                deviceTrail={deviceTrail}
                                reverseTrail={true}
                                disableTrailFocus
                                controls={[
                                  {
                                    element: AssetNames,
                                    position: { bottom: 100, right: 10 },
                                    props: {
                                      value: showAssetNames,
                                      onClick: setShowAssetNames,
                                    },
                                  },
                                ]}
                                fitBoundsProp={JSON.stringify(selectedDevice?.position)}
                              >
                                {selectedDevice && selectedDevice.position && (
                                  <ApiIconMarker
                                    key={selectedDevice.externalId}
                                    color={selectedDevice.color}
                                    iconId={selectedDevice.iconId}
                                    position={selectedDevice.position}
                                    tooltipText={selectedDevice.name}
                                    isAssetMarker
                                  />
                                )}
                              </LeafletMapWithConfigurationStore>
                            </Box>
                          </WidgetPanel>
                        </Box>
                        {permissions[Permissions.CanReadNonSensitiveButtons] && (
                          <WidgetPanel title={T.translate(LocaleKeys.labels.actionButtons)} contentPadding="0">
                            <ActionButtonsWidget
                              assetId={selectedDevice?.externalId}
                              asset={selectedDevice}
                              updateUserSettings={(categoryId) => handleBtnCategoryExpandChange(categoryId)}
                              userSettings={userSettings}
                            />
                          </WidgetPanel>
                        )}
                      </Box>
                    </>
                  );
                }}
              </ContentConnected>
            );
          }}
        </DeviceSidebarView>
      </Box>
    </>
  );
};

const mapDispatch = {
  addThreatBoardDevice,
};

export default compose(
  withPermissions([Permissions.CanReadAlarmNotifications, Permissions.CanReadDevices, Permissions.CanReadAlarms]),
  connect(null, mapDispatch)
)(AlarmTracking);
