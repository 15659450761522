import React, { useState, useEffect, useCallback, useRef } from 'react';
import { withSignalR } from 'context/signalr';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getMapToken } from 'redux/app/selectors';
import T from 'i18n-react';
import { getAlarmsHistory } from 'Api/notifications';
import HistoryView from './HistoryView';
import Filter from './Filter';
import { Box } from '@material-ui/core';
import { CancelToken, isCancel } from 'Api/ApiClient';
import { compareObjects } from 'Helpers/ObjectHelper';
import ErrorMessage from 'Components/display/ErrorMessage';
import LocaleKeys from 'Localization/LocaleKeys';
import { getBatchReverseGeocodeHERE, getReverseGeocodeHERE } from 'Api/maps';

const MESSAGE_TYPE_ALARM = 1;
const MESSAGE_TYPE_TCP = 2;
const MESSAGE_TYPE_URL = 3;

const getHistoryEntryKey = (entry) =>
  `${entry.messageType}${entry.messageType === 1 ? entry.uniqueId : entry.commandExternalId}`;

const isHistoryEntryKeyEqual = (a, b) => getHistoryEntryKey(a) === getHistoryEntryKey(b);

const found = (entry, settings) => {
  if (settings.search === '' || !settings.search) {
    return true;
  }

  return (
    (entry.login && entry.login.toLowerCase().includes(settings.search.toLowerCase())) ||
    (entry.alarmMessage && entry.alarmMessage.toLowerCase().includes(settings.search.toLowerCase())) ||
    (entry.commandName && entry.commandName.toLowerCase().includes(settings.search.toLowerCase())) ||
    (entry.alarmName && entry.alarmName.toLowerCase().includes(settings.search.toLowerCase()))
  );
};

const getCoords = (entries) => {
  return entries
    .filter((entry) => entry.location)
    .map((entry) => ({
      index: entry.index,
      latitude: entry.location.latitude,
      longitude: entry.location.longitude,
    }));
};

const shouldAddRow = (entry, userSettings) => {
  if (entry.messageType === MESSAGE_TYPE_ALARM && !userSettings.inChecked) {
    return false;
  }

  if ([MESSAGE_TYPE_TCP, MESSAGE_TYPE_URL].includes(entry.messageType) && !userSettings.outChecked) {
    return false;
  }

  if (userSettings.activeChecked && !entry.isActive) {
    return false;
  }

  if (!found(entry, userSettings)) {
    return false;
  }

  const date = new Date(entry.createdOn);

  if (userSettings.from && +date < +new Date(userSettings.from)) {
    return false;
  }

  if (userSettings.to && +date > +new Date(userSettings.to)) {
    return false;
  }

  return true;
};

const reverseGeocode = async (entries, mapToken) => {
  if (!entries.length) {
    return [];
  }
  const coords = getCoords(entries);
  const payload = coords.map((c) => `id=${c.index}&in=circle:${c.latitude},${c.longitude};r=50`);

  try {
    const addresses = await getBatchReverseGeocodeHERE(payload, mapToken);
    const locations = addresses.map(({ items }) => items?.[0]?.title || '');

    return entries.map((entry, index) => ({
      ...entry,
      address: locations[index],
    }));
  } catch (error) {
    return entries.map((entry) => ({ ...entry, address: '' }));
  }
};

const areFiltersEqual = (prev, next) => compareObjects(prev, next);

const calculateCount = (pageIndex, rowsPerPage, itemsCount) => pageIndex * rowsPerPage + itemsCount;

const resolvePreviousAlarms = (entries, entriesToBeResolved) => {
  return entries.map((entry) => {
    if (entriesToBeResolved.includes(getHistoryEntryKey(entry))) {
      return { ...entry, isActive: false };
    } else {
      return entry;
    }
  });
};

const HistoryWidget = ({
  device,
  currentSiteId,
  userSettings,
  updateUserSettings,
  mapToken,
  invoke,
  hub,
  isExpanded,
}) => {
  const [historyEntries, setHistoryEntries] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const assetId = device?.externalId;
  const [settings, setSettings] = useState(userSettings);
  const [connected, setConnected] = useState(false);
  const [error, setError] = useState();
  const prevStateRef = useRef({});

  const reverseGeocodeCallback = useCallback((coords) => reverseGeocode(coords, mapToken), [mapToken]);

  const getHistory = useCallback(
    (assetId, settings, cancelToken) => {
      setLoading(true);
      setError(null);
      const params = {
        assetId: assetId,
        includeInbound: settings.inChecked || false,
        includeOutbound: settings.outChecked || false,
        pageIndex: 0,
        pageSize: rowsPerPage,
        includeOnlyActive: settings.activeChecked || false,
        text: settings.search,
        endDate: settings.to,
        startDate: settings.from,
      };

      getAlarmsHistory(params, { cancelToken })
        .then(async ({ data: history }) => {
          const entries = history.entries.map((entry, index) => ({
            ...entry,
            index,
          }));
          const historyEntries = await reverseGeocodeCallback(entries);

          setHistoryEntries(historyEntries);
          setCount(calculateCount(history.pageIndex, history.pageSize, historyEntries.length));
          setHasNextPage(history.hasNextPage);
          setLoading(false);
        })
        .catch((error) => {
          if (!isCancel(error)) {
            setError(error);
            setLoading(false);
          }
        });
    },
    [rowsPerPage, reverseGeocodeCallback]
  );

  const getHistoryPage = useCallback(
    (page, rowsPerPage, cancelToken) => {
      setLoading(true);
      setError(null);
      const params = {
        assetId: assetId,
        includeInbound: settings.inChecked || false,
        includeOutbound: settings.outChecked || false,
        pageIndex: page,
        pageSize: rowsPerPage,
        includeOnlyActive: settings.activeChecked || false,
        text: settings.search,
        endDate: settings.to,
        startDate: settings.from,
      };

      getAlarmsHistory(params, { cancelToken })
        .then(async ({ data: history }) => {
          const entries = history.entries.map((entry, index) => ({
            ...entry,
            index,
          }));
          const historyEntries = await reverseGeocodeCallback(entries);

          setHistoryEntries(historyEntries);
          setCount(calculateCount(history.pageIndex, history.pageSize, historyEntries.length));
          setHasNextPage(history.hasNextPage);
          setLoading(false);
        })
        .catch((error) => {
          if (!isCancel(error)) {
            setError(error);
            setLoading(false);
          }
        });
    },
    [assetId, settings, reverseGeocodeCallback]
  );

  useEffect(() => {
    const source = CancelToken.source();
    const cancelToken = source.token;
    const assetChanged = prevStateRef.current.assetId !== assetId;
    const filtersChanged = !areFiltersEqual(prevStateRef.current.settings, settings);
    const isSourceChecked = settings.inChecked || settings.outChecked;
    const isExpandedChanged = prevStateRef.current.isExpanded !== isExpanded;
    const shouldFetchHistory =
      isExpanded && assetId && (assetChanged || filtersChanged || isExpandedChanged) && isSourceChecked;

    if (shouldFetchHistory) {
      setPage(0);
      getHistory(assetId, settings, cancelToken);
    }

    return () => {
      if (shouldFetchHistory) {
        source.cancel('request "params" have been changed');
      }
    };
  }, [assetId, settings, isExpanded, getHistory]);

  useEffect(() => {
    prevStateRef.current = {
      assetId,
      historyEntries,
      settings,
      rowsPerPage,
      page,
      mapToken,
      isExpanded,
    };
  });

  useEffect(() => {
    if (assetId && hub && !connected) {
      hub.off('History');
      hub.on('History', async (historyEntry) => {
        let decrementCountBy = 0;
        let alarmsKeysToBeResolved = [];

        // if active is checked and new alarm is inactive then uptate isActive flag to false for previous entries with the same alarmId
        if (
          prevStateRef.current.settings.activeChecked &&
          historyEntry.messageType === MESSAGE_TYPE_ALARM &&
          !historyEntry.isActive
        ) {
          alarmsKeysToBeResolved = prevStateRef.current.historyEntries
            .filter(
              (entry) =>
                entry.alarmId === historyEntry.alarmId &&
                entry.isActive &&
                +new Date(entry.createdOn) < +new Date(historyEntry.createdOn)
            )
            .map((entry) => getHistoryEntryKey(entry));
          decrementCountBy = alarmsKeysToBeResolved.length;
        }

        const foundIndex = prevStateRef.current.historyEntries.findIndex((entry) =>
          isHistoryEntryKeyEqual(entry, historyEntry)
        );
        const matchesFilters = shouldAddRow(historyEntry, prevStateRef.current.settings);

        if (foundIndex !== -1) {
          setHistoryEntries((prevEntries) => {
            const newEntries = resolvePreviousAlarms(prevEntries, alarmsKeysToBeResolved);

            newEntries[foundIndex] = { ...newEntries[foundIndex], ...historyEntry };

            return [...newEntries];
          });
          if (!matchesFilters) {
            setCount((prevCount) => prevCount - 1 - decrementCountBy);
          }
        } else if (matchesFilters) {
          if (historyEntry.location && prevStateRef.current.mapToken) {
            const { latitude, longitude } = historyEntry.location;
            const response = await getReverseGeocodeHERE(latitude, longitude, prevStateRef.current.mapToken);

            historyEntry.address = response?.items[0]?.address?.label;
          }
          setCount((prevCount) => prevCount + 1 - decrementCountBy);
          setHistoryEntries((prevEntries) => [
            historyEntry,
            ...resolvePreviousAlarms(prevEntries, alarmsKeysToBeResolved),
          ]);
        } else if (alarmsKeysToBeResolved.length) {
          setCount((prevCount) => prevCount - decrementCountBy);
          setHistoryEntries((prevEntries) => resolvePreviousAlarms(prevEntries, alarmsKeysToBeResolved));
        }
      });
      setConnected(true);
    }
  }, [hub, assetId, connected]);

  useEffect(() => {
    if (assetId && hub) {
      invoke('SubscribeToAsset', currentSiteId, assetId);
    }

    return () => {
      if (assetId && hub) {
        invoke('UnsubscribeFromAsset', currentSiteId, assetId);
      }
    };
  }, [assetId, currentSiteId, hub, invoke]);

  const saveDeviceQueryParams = () => {
    if (assetId) {
      updateUserSettings({ id: assetId, params: settings });
    }
  };

  useEffect(() => {
    saveDeviceQueryParams();
    // eslint-disable-next-line
  }, [settings]);

  const filteredHistoryEntries = historyEntries
    .filter((entry) => shouldAddRow(entry, settings))
    .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))
    .slice(0, rowsPerPage);

  if (error) {
    return (
      <ErrorMessage
        primaryMessage={T.translate(LocaleKeys.messages.errorWhileFetchingData, {
          name: T.translate(LocaleKeys.labels.statusHistory),
        })}
        secondaryMessage={T.translate(LocaleKeys.messages.clickRefreshToTryAgain)}
        onRetry={() => getHistoryPage(page, rowsPerPage)}
      />
    );
  }

  return (
    <Box display="flex" flexDirection="column" maxHeight="316px">
      <Filter
        setHistoryEntries={setHistoryEntries}
        onSettingsChange={setSettings}
        settings={userSettings}
        setPage={setPage}
        disabled={!assetId}
      />
      <HistoryView
        setPage={setPage}
        page={page}
        setRowsPerPage={setRowsPerPage}
        rowsPerPage={rowsPerPage}
        count={hasNextPage ? -1 : count}
        setSelectedRow={setSelectedRow}
        device={device}
        loading={loading}
        entries={filteredHistoryEntries}
        selectedRow={selectedRow}
        fetchPage={getHistoryPage}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  currentSiteId: state.sites.currentSiteId,
  mapToken: getMapToken(state),
});

export default compose(withSignalR, connect(mapStateToProps))(HistoryWidget);
