import React from 'react';
import { styled } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import ErrorIcon from '@material-ui/icons/Error';
import EmptyIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import FullIcon from './FullIcon';
import RackFrame from './RackFrame';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ClosedIcon from '@material-ui/icons/Lock';
import { Typography } from '@material-ui/core';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';

const Wrapper = styled('div')({
  width: 224,
  height: 78,
  padding: `12px 12px 8px 20px`,
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: `space-between`,
  background: '#F7F7F7',
  position: 'relative',
});

const RackTitle = styled(Typography)({
  fontWeight: 900,
});

const IconsWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const COLORS = {
  unknown: '#D4D4D4',
  closedEmpty: '#138A00',
  closedFull: '#2C2C54',
  error: '#DE3232',
  open: '#E4CF17',
};

const CompartmentState = {
  unknown: 0,
  open: 1,
  closedFull: 2,
  closedEmpty: 3,
  error: 4,
};

export const RackItem = ({ rfId, compartmentState, message }) => {
  if (compartmentState === CompartmentState['open']) {
    return (
      <Wrapper>
        <RackFrame fill={COLORS.open} />
        <RackTitle variant="caption">{message}</RackTitle>
        {rfId && <RackTitle variant="caption">{rfId}</RackTitle>}
        <LockOpenIcon style={{ width: 16 }} />
      </Wrapper>
    );
  }

  if (compartmentState === CompartmentState['closedFull']) {
    return (
      <Wrapper>
        <RackFrame fill={COLORS.closedFull} />
        <RackTitle variant="caption">{message}</RackTitle>
        {rfId && <RackTitle variant="caption">{rfId}</RackTitle>}
        <IconsWrapper>
          <FullIcon />
          <ClosedIcon style={{ width: 16, marginLeft: 4 }} />
        </IconsWrapper>
      </Wrapper>
    );
  }

  if (compartmentState === CompartmentState['closedEmpty']) {
    return (
      <Wrapper>
        <RackFrame fill={COLORS.closedEmpty} />
        <RackTitle variant="caption">{message}</RackTitle>
        {rfId && <RackTitle variant="caption">{rfId}</RackTitle>}
        <IconsWrapper>
          <EmptyIcon style={{ width: 16 }} />
          <ClosedIcon style={{ width: 16, marginLeft: 4 }} />
        </IconsWrapper>
      </Wrapper>
    );
  }
  if (compartmentState === CompartmentState['error']) {
    return (
      <Wrapper>
        <RackFrame fill={COLORS.error} />
        <RackTitle variant="caption">{message}</RackTitle>
        {rfId && <RackTitle variant="caption">{rfId}</RackTitle>}
        <ErrorIcon style={{ width: 16 }} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <RackFrame fill={COLORS.unknown} />
      <RackTitle variant="caption">{T.translate(LocaleKeys.messages.unknownNoDataFromDevice)}</RackTitle>
      <HelpIcon style={{ width: 16 }} />
    </Wrapper>
  );
};
