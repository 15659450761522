import { CachedTileLayer } from '@yaga/leaflet-cached-tile-layer';

const baseURL = 'maps.hereapi.com/v3';
const size = 256;

export const LayerTypes = {
  BASE: 1,
  OVERLAY: 2,
};

const Layers = (token) => {
  return {
    baseLayer: new CachedTileLayer(
      `https://${baseURL}/background/mc/{z}/{x}/{y}/png?size=${size}&style=logistics.day&lang=en&${token}`,
      {
        maxZoom: 20,
        size: 256,
        layerKey: 'baseLayer',
        layerType: LayerTypes.BASE,
      }
    ),
    sateliteLayer: new CachedTileLayer(
      `https://${baseURL}/background/mc/{z}/{x}/{y}/png?size=${size}&style=satellite.day&lang=en&${token}`,
      {
        maxZoom: 20,
        size: 256,
        layerKey: 'sateliteLayer',
        layerType: LayerTypes.BASE,
      }
    ),
    terrainLayer: new CachedTileLayer(
      `https://${baseURL}/background/mc/{z}/{x}/{y}/png?size=${size}&style=topo.day&lang=en&${token}`,
      {
        maxZoom: 20,
        size: 256,
        layerKey: 'terrainLayer',
        layerType: LayerTypes.BASE,
      }
    ),
    labelTile: new CachedTileLayer(
      `https://${baseURL}/label/mc/{z}/{x}/{y}/png?size=${size}&style=explore.day&lang=en&${token}`,
      {
        maxZoom: 20,
        size: 256,
        layerKey: 'labelTile',
        layerType: LayerTypes.OVERLAY,
      }
    ),
    traficLayer: new CachedTileLayer(
      `https://traffic.${baseURL}/flow/mc/{z}/{x}/{y}/png?size=${size}&style=explore.day&lang=en&${token}`,
      {
        maxZoom: 20,
        size: 256,
        layerKey: 'traficLayer',
        layerType: LayerTypes.OVERLAY,
      }
    ),
    truckRestriction: new CachedTileLayer(
      `https://${baseURL}/base/mc/{z}/{x}/{y}/png?size=${size}&style=explore.day&features=vehicle_restrictions:active_and_inactive&lang=en&${token}`,
      {
        maxZoom: 20,
        size: 256,
        layerKey: 'truckRestriction',
        layerType: LayerTypes.OVERLAY,
      }
    ),
  };
};

export default Layers;
