import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Dashboard } from 'routes/Routes';
import { Box, CircularProgress, Button } from '@material-ui/core';
import T from 'i18n-react';
import { styled } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import LocaleKeys from 'Localization/LocaleKeys';
import { loadFail, loadSuccess, setLoading } from 'redux/assets/actions';
import { getMapToken } from 'redux/app/selectors';
import { getLoadingAssets } from 'redux/assets/selectors';
import { connect } from 'react-redux';
import { getDevicesDashboard } from 'Api/devices';
import { getBatchReverseGeocodeHERE } from 'Api/maps';
import Filters, { filterDevices } from './Filters';
import AssetList from './AssetList';
import { CancelToken, isCancel } from 'Api/ApiClient';

const Wrapper = styled(Box)({
  width: 470,
  paddingRight: 20,
});
const Loading = () => (
  <Box align="center" p={4}>
    <CircularProgress />
  </Box>
);

const BackButton = styled(Button)(() => ({
  backgroundColor: '#3f51b5',
  color: '#fff',
  borderRadius: '32px',
  height: '48px',
  width: '200px',
  '&:hover': {
    backgroundColor: '#3f51b5',
  },
}));
const BackToTracking = styled('div')({
  height: '70px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const reverseGeocodePositions = async (assets, mapToken) => {
  const assetsWithPosition = assets
    .map(({ position }, index) => ({ index, position }))
    .filter((asset) => asset.position);

  const coords = assetsWithPosition.map(
    ({ index, position }) => `id=${index}&in=circle:${position.latitude},${position.longitude};r=50`
  );
  const addressesMap = {};
  let addresses;

  try {
    const response = await getBatchReverseGeocodeHERE(coords, mapToken);

    addresses = response.map(({ id, items }) => ({
      index: id,
      address: items?.[0]?.title || '',
      error: null,
    }));
  } catch (error) {
    addresses = assetsWithPosition.map(({ index }) => ({
      index,
      address: null,
      error,
    }));
  }

  addresses.forEach(({ index, address, error }) => {
    addressesMap[index] = { address, error };
  });

  return assets.map((asset, index) => {
    if (addressesMap[index]) {
      return {
        ...asset,
        position: {
          ...asset.position,
          address: addressesMap[index].address,
          error: addressesMap[index].error,
        },
      };
    }

    return asset;
  });
};

const mapState = (state) => ({
  mapToken: getMapToken(state),
  loading: getLoadingAssets(state),
  currentSiteId: state.sites.currentSiteId,
});

const mapDispatch = {
  loadSuccess,
  loadFail,
  setLoading,
};

const Sidebar = connect(
  mapState,
  mapDispatch
)(
  ({
    devices,
    error,
    selectedAssetIds,
    setSelectedAssetIds,
    selectDeviceGeoPos,
    deviceTrail,
    displayBackToDashboardButton,
    loadSuccess,
    loadFail,
    loading,
    setLoading,
    mapToken,
    currentSiteId,
    options = {},
    rowsWithVisibleAddressIds,
    setRowsWithVisibleAddressIds,
  }) => {
    const history = useHistory();
    const [query, setQuery] = useState('');
    const [active, setActive] = useState(false);
    const [filter, setFilter] = useState('all');
    const requestIdRef = useRef(+new Date());

    const navigateToDashboard = () => {
      history.push(Dashboard.getUrl());
    };

    const filteredDevices = useMemo(
      () => filterDevices(devices, { query, active, filter }),
      [devices, query, active, filter]
    );

    useEffect(() => {
      const source = CancelToken.source();
      const requestId = requestIdRef.current;

      setLoading({ loading: true });
      getDevicesDashboard({ cancelToken: source.token })
        .then(async ({ data: devices }) => {
          if (mapToken) {
            devices = await reverseGeocodePositions(devices, mapToken);
          }

          if (requestIdRef.current === requestId) {
            loadSuccess({
              devices,
            });
          }
        })
        .catch((error) => {
          if (!isCancel(error)) {
            loadFail({ error });
          }
        });

      return () => {
        requestIdRef.current = +new Date();
        source.cancel();
      };
    }, [loadSuccess, loadFail, mapToken, setLoading, currentSiteId]);

    return (
      <Wrapper>
        <Filters
          active={active}
          setActive={setActive}
          query={query}
          setQuery={setQuery}
          filter={filter}
          setFilter={setFilter}
        />
        {loading ? (
          <Loading />
        ) : (
          <AssetList
            devices={filteredDevices}
            deviceTrail={deviceTrail}
            displayBackToDashboardButton={displayBackToDashboardButton}
            selectedAssetIds={selectedAssetIds}
            setSelectedAssetIds={setSelectedAssetIds}
            rowsWithVisibleAddressIds={rowsWithVisibleAddressIds}
            setRowsWithVisibleAddressIds={setRowsWithVisibleAddressIds}
            selectDeviceGeoPos={selectDeviceGeoPos}
            options={options}
            error={error}
            mapToken={mapToken}
          />
        )}
        {displayBackToDashboardButton && (
          <BackToTracking>
            <BackButton onClick={navigateToDashboard} startIcon={<ArrowBack />}>
              {T.translate(LocaleKeys.labels.backToTracking)}
            </BackButton>
          </BackToTracking>
        )}
      </Wrapper>
    );
  }
);

export default Sidebar;
